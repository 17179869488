import { Flex } from '../../primitives'
import { ButtonWhite } from '../../shareable/buttons/ButtonWhite'
import { ButtonPrimaryLight } from '../../shareable/buttons/ButtonPrimaryLight'
import React from 'react'
import { Loading } from '../../shareable/loading/loading'
import { ButtonPrimary } from '../../shareable/buttons/ButtonPrimary'
import { useTranslation } from "react-i18next";

export const BackAndNext = ({
  canClickNext,
  backClick,
  nextClick,
  disableClick,
  isLoading,
  // backTitle = 'Bakåt',
  // nextTitle = 'Nästa'
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Flex
        display={['none', 'none', 'flex']}
        width={'100%'}
        pb={[0, 0, 5]}
        mt={[10, 10, 10]}
        alignItems={'flex-end'}
        jusifyContent={'space-between'}>
        <ButtonWhite onClick={backClick}>{t('basket_get_basket_button')}</ButtonWhite>
        <Loading isLoading={isLoading} mb={1}>
          {canClickNext ? (
            <ButtonPrimary onClick={nextClick}>{t('file_step_text_10')}</ButtonPrimary>
          ) : (
            <ButtonPrimaryLight
              onClick={() => {
                if (disableClick) {
                  disableClick()
                }
              }}>
              {t('file_step_text_10')}
            </ButtonPrimaryLight>
          )}
        </Loading>
      </Flex>

      <Flex
        display={['flex', 'flex', 'none']}
        width={'100%'}
        style={{ position: 'fixed', bottom: 0 }}
        bg={'white'}
        p={2}
        jusifyContent={'space-between'}>
        <ButtonWhite minWidth={'60px'} onClick={backClick}>
          {t('basket_get_basket_button')}
        </ButtonWhite>
        <Loading isLoading={isLoading} mb={1}>
          {canClickNext ? (
            <ButtonPrimary minWidth={'60px'} onClick={nextClick}>
              {t('file_step_text_10')}
            </ButtonPrimary>
          ) : (
            <ButtonPrimaryLight
              minWidth={'60px'}
              onClick={() => {
                if (disableClick) {
                  disableClick()
                }
              }}>
              {t('file_step_text_10')}
            </ButtonPrimaryLight>
          )}
        </Loading>
      </Flex>
    </>
  )
}
