import axios from 'axios'
import { ShowError, ShowInfo } from '../helpers/showError'

export const addDiscountCodeToOrder = async ({
  orderId,
  discountCode,
  productOption,
  discountNotFound,
  discountError
}) => {
  
  try {

    const url = process.env.REACT_APP_API_URL + '/addDiscountCodeToOrder'
    const response = await axios({
      method: 'post',
      url: url,
      data: {
        orderId,
        discountCode,
        productOption
      },
      headers: {
        'content-type': 'application/json'
      }
    })

    return response.data
  } catch (e) {
    console.log('erororo', e.response.status)
    if (e.response.status === 404) {
      ShowInfo({
        message: discountNotFound
      })
    } else {
      ShowError({
        message: discountError
      })
    }
  }
}


