import React, { useState } from 'react'
import { Flex, ImageContain, Text } from '../../primitives'
import style1 from '../../../images/Arbetsrum.svg'
import style2 from '../../../images/Balkong.svg'
import style3 from '../../../images/Hall.svg'
import style4 from '../../../images/Kök.svg'
import style5 from '../../../images/Konferansrum.svg'
import style6 from '../../../images/Sovrum.svg'
import style7 from '../../../images/Vardagsrum.svg'
import { LoadingSpinner } from '../../shareable/lottie/LoadingSpinner'
import styled from 'styled-components'
import { useTranslation } from "react-i18next";

const FlexHover = styled(Flex)`
  &:hover {
    background-color: lightgrey;
  }
`

const Room = ({ room, img, price, onClick }) => {
  const [isLoading, setIsLoading] = useState(false)
  return (
    <FlexHover
      onClick={() => {
        setIsLoading(true)
        onClick()
      }}
      pt={4}
      height={'80px'}
      pb={4}
      style={{ borderBottom: '1px solid grey', cursor: 'pointer' }}>
      <ImageContain height={'40px'} width={'40px'} src={img} />
      <Flex ml={4} jusifyContent={'space-between'} flexDirection={'column'}>
        <Text fontWeight={600}>{room}</Text>
        <Text>{price}</Text>
      </Flex>
      <Flex flex={1} jusifyContent={'flex-end'}>
        {isLoading ? (
          <Flex width={'50px'} height={'30px'}>
            <LoadingSpinner />
          </Flex>
        ) : null}
      </Flex>
    </FlexHover>
  )
}

export const AddRoomDropDown = ({ addRoom, price, isBusiness }) => {
  const { t } = useTranslation();
  if (isBusiness) {
    return (
      <Flex flexDirection={'column'}>
        <Room
          room={'Space/rum'}
          onClick={() => {
            addRoom('space/rum')
          }}
          price={price}
          img={style1}
        />
      </Flex>
    )
  }
  return (
    <Flex flexDirection={'column'}>
      <Room
        room={t('choose_room_text_4')}
        onClick={() => {
          addRoom('arbetsplats')
        }}
        price={price}
        img={style1}
      />
      <Room
        room={t('choose_room_text_5')}
        img={style2}
        price={price}
        onClick={() => {
          addRoom('uteplats')
        }}
      />
      <Room
        room={t('choose_room_text_6')}
        img={style3}
        price={price}
        onClick={() => {
          addRoom('hall')
        }}
      />
      <Room
        room={t('choose_room_text_7')}
        img={style4}
        price={price}
        onClick={() => {
          addRoom('kök')
        }}
      />
      <Room
        room={t('choose_room_text_8')}
        img={style5}
        price={price}
        onClick={() => {
          addRoom('matplats')
        }}
      />
      <Room
        room={t('choose_room_text_9')}
        img={style6}
        price={price}
        onClick={() => {
          addRoom('sovrum')
        }}
      />
      <Room
        room={t('choose_room_text_10')}
        img={style7}
        price={price}
        onClick={() => {
          addRoom('vardagsrum')
        }}
      />
    </Flex>
  )
}
