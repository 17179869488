import axios from 'axios'
import { ShowError } from '../helpers/showError'

export const createCheckout = async ({ rooms, orderId, lang, createCheckoutError}) => {
  try {
    const url = process.env.REACT_APP_API_URL + '/createCheckout'
    const response = await axios({
      method: 'post',
      url: url,
      data: {
        rooms,
        orderId,
        lang
      },
      headers: {
        'content-type': 'application/json'
      }
    })

    return response.data
  } catch (e) {
    console.log('erororo', e.response.data)
    ShowError({
      message: createCheckoutError
    })
  }
}
