import React, { useEffect, useState } from 'react'
import { Flex, Text } from '../components/primitives'
import { getOrderFromKlarnaId } from '../api/getOrderFromKlarnaId'
import { LoadingSpinner } from '../components/shareable/lottie/LoadingSpinner'
import { useTranslation } from "react-i18next";

const getPrice = (respone) => {
  if (respone && respone.checkout && respone.checkout.order_amount) {
    return Number(respone.checkout.order_amount) / 100
  }
  return 0
}
export const Confirmation = () => {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation();

  useEffect(() => {
    const klarnaId = new URLSearchParams(window.location.search).get('klarnaId')
    const language = t('language')
    const getOrder = async () => {
      setLoading(true)
      const respone = await getOrderFromKlarnaId({ klarnaId, language })
      setLoading(false)

      try {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'transaction',
          transactionId: klarnaId,
          transactionTotal: getPrice(respone)
        })
      } catch (e) {
        console.log(e)
      }
    }

    getOrder()
  }, [])

  return (
    <Flex jusifyContent={'center'}>
      <Flex
        mt={['80px', '80px', '120px']}
        maxWidth={'1000px'}
        minHeight={'100vh'}
        p={4}
        flexDirection={'column'}>
        {loading ? (
          <Flex mt={4} width={'100%'} jusifyContent={'center'} height={'40px'}>
            <Flex width={'100px'} height={'40px'}>
              <LoadingSpinner />
            </Flex>
          </Flex>
        ) : (
          <>
            <Text textAlign={'center'} fontWeight={'bold'} fontSize={8} mb={4}>
              {t('confirmation_text_1')}
            </Text>
            <Text textAlign={'center'} mb={2}>
              {t('confirmation_text_2')}
            </Text>
            <Text textAlign={'center'}>
              {t('confirmation_text_3')}
            </Text>
          </>
        )}
      </Flex>
    </Flex>
  )
}
