import React, { useLayoutEffect, useRef, useState } from 'react'
import { Flex, ImageContain, Text } from '../primitives'
import { TextWithClick } from '../shareable/text/TextWithHover'
import { ButtonWhite } from '../shareable/buttons/ButtonWhite'
import style1 from '../../images/Arbetsrum.svg'
import style2 from '../../images/Balkong.svg'
import style3 from '../../images/Hall.svg'
import style4 from '../../images/Kök.svg'
import style5 from '../../images/Konferansrum.svg'
import style6 from '../../images/Sovrum.svg'
import style7 from '../../images/Vardagsrum.svg'
import { Close } from '@styled-icons/evil/Close'
import { ChevronDown } from '@styled-icons/evil/ChevronDown'
import { Input } from '../shareable/form/Input'
import { Loading } from '../shareable/loading/loading'
import { ButtonPrimaryLight } from '../shareable/buttons/ButtonPrimaryLight'
import { addRoomToOrder } from '../../api/addRoomToOrder'
import { updateCheckout } from '../../api/updateCheckout'
import { LoadingSpinner } from '../shareable/lottie/LoadingSpinner'
import { addDiscountCodeToOrder } from '../../api/addDiscountCodeToOrder'
import { AddRoomDropDown } from './components/AddRoomDropDown'
import { ButtonPicker } from '../shareable/buttons/ButtonPicker'
import { addOptionToOrder } from '../../api/addOptionToOrder'
// import {
//   businessPrice,
//   conceptPrice,
//   guidancePrice,
//   twoDPlanPrice
// } from '../../helpers/config'
import { InfoBusinessText } from './BusinessSpaceCount'
import { useTranslation } from "react-i18next";


const renderKlarna = (htmlSnippet, ref, currency) => {
  const checkoutContainer = ref
  checkoutContainer.innerHTML = htmlSnippet
  const scriptsTags = checkoutContainer.getElementsByTagName('script')
  // This is necessary otherwise the scripts tags are not going to be evaluated
  for (let i = 0; i < scriptsTags.length; i++) {
    const parentNode = scriptsTags[i].parentNode
    const newScriptTag = document.createElement('script')
    newScriptTag.type = 'text/javascript'
    newScriptTag.text = scriptsTags[i].text

    if (scriptsTags[i].text.includes('window._klarnaCheckout')) {
      newScriptTag.text = newScriptTag.text.replace(
        'window._klarnaCheckout(function(api) {',
        `window._klarnaCheckout(function(api) {
          api.setCurrency(${currency});`
      )
    }

    parentNode.removeChild(scriptsTags[i])
    parentNode.appendChild(newScriptTag)
  }
}

const getRoomImg = (room) => {
  if (room === 'arbetsplats') {
    return style1
  } else if (room === 'uteplats') {
    return style2
  } else if (room === 'hall') {
    return style3
  } else if (room === 'kök') {
    return style4
  } else if (room === 'matplats') {
    return style5
  } else if (room === 'sovrum') {
    return style6
  } else if (room === 'vardagsrum') {
    return style7
  } else if (room === 'space/rum') {
    return style1
  }
}

const Item = ({ room, removeRoom, isLoading, price }) => {
  return (
    <Flex
      mt={4}
      height={'80px'}
      pb={4}
      style={{ borderBottom: '1px solid grey' }}>
      <ImageContain height={'60px'} width={'60px'} src={getRoomImg(room)} />
      <Flex ml={4} jusifyContent={'space-between'} flexDirection={'column'}>
        <Text fontWeight={600} style={{ textTransform: 'capitalize' }}>
          {room}
        </Text>
        <Text>{price}</Text>
      </Flex>
      <Flex flex={1} jusifyContent={'flex-end'}>
        {isLoading ? (
          <Flex width={'50px'} height={'30px'}>
            <LoadingSpinner />
          </Flex>
        ) : (
          <Close
            onClick={removeRoom}
            style={{ cursor: 'pointer' }}
            size={'30px'}
          />
        )}
      </Flex>
    </Flex>
  )
}

const klarnaSpinner = (value) => {
  if (value) {
    window._klarnaCheckout(function(api) {
      api.suspend()
    })
  } else {
    window._klarnaCheckout(function(api) {
      api.resume()
    })
  }
}

const RegularText = () => {
  const { t } = useTranslation();
  return (
    <Text width={'100%'} bg={'grey'} p={4}>
      {t('basket_regular_text')}
    </Text>
  )
}

const ConceptText = () => {
  const { t } = useTranslation();
  return (
    <Text width={'100%'} bg={'grey'} p={4}>
      {t('basket_concept_text')}
      <br />
      <br />
      {t('basket_concept_text_title')} <br />
      <ul style={{ paddingRight: '20px' }}>
        <li>{t('basket_concept_text_li_1')}</li>
        <li>{t('basket_concept_text_li_2')}</li>
        <li>{t('basket_concept_text_li_3')}</li>
        <li>{t('basket_concept_text_li_4')}</li>
        <li>{t('basket_concept_text_li_5')}</li>
        <li>{t('basket_concept_text_li_6')}</li>
        <li>{t('basket_concept_text_li_7')}</li>
        <li>{t('basket_concept_text_li_8')}</li>
      </ul>
      {t('basket_concept_li_end_text')}{' '}
      <a href={'https://www.hemset.se/faq/'} target='_blank'>
        här
      </a>
    </Text>
  )
}
const BusinessText = () => {
  const { t } = useTranslation();
  return (
    <Text width={'100%'} bg={'grey'} p={4}>
      {t('basket_concept_text')}
      <br />
      <br />
      {t('basket_concept_text_title')} <br />
      <ul style={{ paddingRight: '20px' }}>
        <li>{t('basket_concept_text_li_1')}</li>
        <li>{t('basket_concept_text_li_2')}</li>
        <li>{t('basket_concept_text_li_3')}</li>
        <li>{t('basket_concept_text_li_4')}</li>
        <li>{t('basket_concept_text_li_5')}</li>
        <li>{t('basket_concept_text_li_6')}</li>
        <li>{t('basket_concept_text_li_7')}</li>
        <li>{t('basket_concept_text_li_8')}</li>
      </ul>
      {t('basket_concept_li_end_text')}{' '}
      <a href={'https://www.hemset.se/faq/'} target='_blank'>
        här
      </a>
    </Text>
  )
}

const BusniessText = () => {
  const { t } = useTranslation();
  return (
    <Text width={'100%'} bg={'grey'} p={4}>
      {t('basket_concept_text')}
      <br />
      <br />
      {t('basket_concept_text_title_biz')} <br />
      <ul style={{ paddingRight: '20px' }}>
        <li>{t('basket_concept_text_li_2')}</li>
        <li>{t('basket_concept_text_li_3')}</li>
        <li>{t('basket_concept_text_li_4')}</li>
        <li>{t('basket_concept_text_li_5')}</li>
        <li>{t('basket_concept_text_li_6')}</li>
        <li>{t('basket_concept_text_li_7')}</li>
        <li>{t('basket_concept_text_li_8')}</li>
      </ul>
      {t('basket_concept_li_end_text')}{' '}
      <a href={'https://www.hemset.se/faq/'} target='_blank'>
        här
      </a>
    </Text>
  )
}

const GuideText = () => {
  const { t } = useTranslation();
  return (
    <Text width={'100%'} bg={'grey'} p={4}>
      {t('basket_guide_text_1')}
      <br />
      <br />
      {t('basket_guide_text_2')}  <br />
      <ul style={{ paddingRight: '20px' }}>
        <li>{t('basket_guide_text_li_1')}</li>
        <li>{t('basket_guide_text_li_2')}</li>
        <li>{t('basket_guide_text_li_3')}</li>
      </ul>
      {t('basket_guide_text_3')}{' '}
      <a href={'https://www.hemset.se/faq/'} target='_blank'>
        här
      </a>
    </Text>
  )
}

export const Basket = ({
  checkout,
  rooms,
  backClick,
  orderId,
  changeRoom,
  changeOption,
  productOption,
  showBackButton = true,
  isBusiness
}) => {
  const [showDiscount, setShowDiscount] = useState(false)
  const [showAddRoom, setShowAddRoom] = useState(false)
  const [discountCode, setDiscountCode] = useState('')
  const [addDiscountLoading, setAddDiscountLoading] = useState(false)
  const [discountCodeSaved, setDiscountCodeSaved] = useState(false)
  const [removeLoading, setRemoveLoading] = useState(false)
  const klarnaHolderRef = useRef(null)
  const { t } = useTranslation();

  const currency = t('currency');

  const gprice = t('guidancePrice') + ' ' + t('currency');
  const twoprice = t('twoDPlanPrice') + ' ' + t('currency');
  const cprice = t('conceptPrice') + ' ' + t('currency');
  const getPrice = (option) => {
    switch (option) {
      case 'guidance':
        return gprice
      case '2d-plan':
        return twoprice
      case 'concept':
        return cprice
  
      default:
        return cprice
    }
  }
  useLayoutEffect(() => {
    // renderKlarna(checkout.html_snippet, klarnaHolderRef.current)
    renderKlarna(checkout.html_snippet, klarnaHolderRef.current, currency)
    // eslint-disable-next-line
  }, [])

  console.log('productOption:' + productOption)

  const getBasket = () => {
    return (
      <Flex
        width={'100%'}
        flex={1}
        minHeight={'100%'}
        style={{ position: 'relative', overflow: 'hidden', overflowY: 'auto' }}
        flexDirection={'column'}
        mb={10}>
        <Flex flexDirection={'column'} style={{ position: 'absolute' }}>
          <Flex flexDirection={'column'} width={'100%'}>
            <Flex width={'100%'} jusifyContent={'center'} p={2} mt={[2, 2, 0]}>
              {!isBusiness && (
                <ButtonPicker
                  mb={0}
                  titleOne={t("basket_get_basket_title_1")}
                  titleTwo={t("basket_get_basket_title_2")}
                  titleThree={t("basket_get_basket_title_3")}
                  onSelected={async (v) => {
                    setRemoveLoading(true)
                    klarnaSpinner(true)
                    let option = v
                    await addOptionToOrder({ orderId: orderId, option: option, lang: t('language') })
                    await updateCheckout({
                      orderId: orderId,
                      lang: t('language'),
                      addOptionError: t('add_option_text')
                    })
                    changeOption(option)
                    setRemoveLoading(false)
                    klarnaSpinner(false)
                  }}
                  initalOneValue={productOption}
                />
              )}
            </Flex>
            <Text fontSize={'22px'}>{t("basket_get_basket_text_1")}</Text>
            <Flex flexDirection={'column'}>
              {rooms &&
                rooms.map((room, index) => {
                  return (
                    <Item
                      key={index}
                      isLoading={removeLoading}
                      removeRoom={async () => {
                        setRemoveLoading(true)
                        klarnaSpinner(true)
                        const filteredRooms = rooms.filter(
                          (r, i) => i !== index
                        )
                        await addRoomToOrder({
                          orderId: orderId,
                          rooms: filteredRooms,
                          roomOrderError: t('add_room_text')
                        })
                        const k = await updateCheckout({
                          orderId: orderId,
                          lang: t('language'),
                          updateCheckoutError: t('update_checkout_text')
                        })

                        if (k) {
                          changeRoom(filteredRooms)
                        }
                        setRemoveLoading(false)
                        klarnaSpinner(false)
                      }}
                      price={
                        isBusiness ? t('businessPrice')+ ' ' +t('currency') : getPrice(productOption)
                      }
                      room={room}
                    />
                  )
                })}
            </Flex>
          </Flex>

          <Flex flexDirection={'column'} width={'100%'}>
            <Flex mt={2} mb={4} jusifyContent={'space-between'}>
              <Flex
                onClick={() => {
                  setShowAddRoom(!showAddRoom)
                }}>
                <TextWithClick mr={1} style={{ textDecoration: 'underline' }}>
                  {t('basket_get_basket_text_2')}
                </TextWithClick>

                <ChevronDown size={'20px'} />
              </Flex>
            </Flex>
            {showAddRoom && (
              <AddRoomDropDown
                isBusiness={isBusiness}
                price={isBusiness ? t('businessPrice')+ ' ' +t('currency') : getPrice(productOption)}
                addRoom={async (room) => {
                  klarnaSpinner(true)
                  await addRoomToOrder({
                    orderId: orderId,
                    rooms: [...rooms, room],
                    roomOrderError: t('add_room_text')
                  })
                  const k = await updateCheckout({
                    orderId: orderId,
                    lang: t('language'),
                    updateCheckoutError: t('update_checkout_text')
                  })

                  if (k) {
                    changeRoom([...rooms, room])
                  }

                  klarnaSpinner(false)
                  setShowAddRoom(false)
                }}
              />
            )}
          </Flex>

          <Flex flexDirection={'column'} width={'100%'}>
            <Flex mt={2} mb={4} jusifyContent={'space-between'}>
              <Flex
                onClick={() => {
                  setShowDiscount(!showDiscount)
                }}>
                <TextWithClick mr={1} style={{ textDecoration: 'underline' }}>
                  {t('basket_get_basket_text_3')}
                </TextWithClick>

                <ChevronDown size={'20px'} />
              </Flex>
            </Flex>
          </Flex>

          {showDiscount && (
            <Flex flexDirection={'column'} width={'100%'}>
              {discountCodeSaved && (
                <Text color={'green'} mb={2}>
                  {t('basket_get_basket_text_4')}
                </Text>
              )}
              <Flex mb={4} flexDirection={'column'} pr={2}>
                <Flex>
                  <Input
                    placeholder={t('basket_get_basket_dscode')}
                    value={discountCode}
                    onChange={(e) => {
                      setDiscountCode(e.target.value)
                    }}
                  />
                </Flex>
                <Flex mt={2} jusifyContent={'flex-end'}>
                  <Loading isLoading={addDiscountLoading}>
                    <ButtonPrimaryLight
                      onClick={async () => {
                        klarnaSpinner(true)
                        setAddDiscountLoading(true)
                        const c = await addDiscountCodeToOrder({
                          orderId: orderId,
                          discountCode: discountCode,
                          productOption: productOption,
                          discountNotFound: t('add_discount_message_text_info'),
                          discountError: t('add_discount_message_text_err')
                        })
                        if (c) {
                          setDiscountCodeSaved(true)
                        }
                        setAddDiscountLoading(false)
                        klarnaSpinner(false)
                        setDiscountCode('')
                      }}
                      minWidth={'50px'}>
                      {t('basket_get_basket_text_5')}
                    </ButtonPrimaryLight>
                  </Loading>
                </Flex>
              </Flex>
            </Flex>
          )}
          <Flex
            width={'100%'}
            flexDirection={'column'}
            height={'150px'}
            style={{ alignSelf: 'flex-end' }}>
            {isBusiness ? <BusniessText /> : <RegularText />}
            <Flex mt={4} mb={4}>
              {showBackButton && (
                <ButtonWhite onClick={backClick}>{t('basket_get_basket_button')}</ButtonWhite>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    )
  }
  return (
    <Flex
      width={'100%'}
      flex={1}
      flexDirection={['column', 'column', 'row']}
      style={{ overflowY: 'hidden' }}>
      <Flex
        display={['none', 'none', 'flex']}
        mr={4}
        flex={1}
        flexDirection={'column'}>
        {getBasket()}
      </Flex>

      <Flex
        id={'klarnaholderfixforios'}
        ml={[0, 0, 6]}
        width={['100%', '100%', '340px']}
        flexDirection={'column'}
        alignItems={'center'}
        style={{ position: 'relative', overflow: 'hidden', overflowY: 'auto' }}
        height={['100%', '60%', '100%']}>
        <Flex width={['100%', '100%', '340px']} ref={klarnaHolderRef} />
        <Flex
          height={'800px'}
          width={'100%'}
          flexDirection={'column'}
          display={['flex', 'flex', 'none']}>
          {getBasket()}
        </Flex>
      </Flex>
    </Flex>
  )
}
