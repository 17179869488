import React from 'react'
import Loadable from "@loadable/component"

const Lottie = Loadable(() => import('react-lottie'))


export const LoadingSpinner = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require('./json/loading'),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return <Lottie options={defaultOptions} />
}
