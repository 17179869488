import styled from 'styled-components'
import { Button } from '../../primitives'
import { darken } from 'polished'
import { colors } from '../../../other/colors/color'

const ButtonWhite = styled(Button)`
  border: ${(props) => props.border};
  &:hover {
    background-color: ${darken(0.05, colors.white)};
  }
  &:focus {
    outline: none;
  }
`

ButtonWhite.defaultProps = {
  bg: 'white',
  color: 'primaryLight',
  border: '1px solid #c9b49e'
}

export { ButtonWhite }
