import { Image as ReImage } from 'rebass/styled-components'
import styled from 'styled-components'
import { display } from 'styled-system'

export const ImageCoverRound = styled(ReImage)`
  object-fit: cover;
  border-radius: 10px;
  ${display}
`

export const ImageCover = styled(ReImage)`
  object-fit: cover;
  ${display}
`

export const ImageContain = styled(ReImage)`
  object-fit: contain;
  ${display}
`
