import React, { useEffect, useState } from 'react'
import { Flex, Text } from '../primitives'
import { ChooseFurnishings } from './ChooseFurnishings'
import { ChooseRoom } from './ChooseRoom'
import { Budget } from './Budget'
import { Start } from './Start'
import { Basket } from './Basket'
import { SaveOrder } from './SaveOrder'
import { getNotPaidOrder } from '../../api/getNotPaidOrder'
import { Loading } from '../shareable/loading/loading'
import { Option } from './Option'
import { Message } from './Message'
import { createCheckout } from '../../api/createCheckout'
import { useTranslation } from "react-i18next";


const Step = ({ selected }) => {
  return (
    <Flex
      bg={selected ? 'primary' : 'grey'}
      height={selected ? '4px' : '2px'}
      flex={1}
    />
  )
}
const Stepper = ({ step }) => {
  return (
    <Flex
      width={'100%'}
      flexDirection={'column'}
      alignItems={'center'}
      jusifyContent={'center'}>
      <Flex pb={[1, 1, 4]}>
        <Text color={'black'} mr={1}>
          {step}
        </Text>
        <Text color={'lightGrey'} mr={1}>
          /
        </Text>
        <Text color={'lightGrey'}>5</Text>
      </Flex>
      <Flex width={'100%'}>
        <Step selected={step > 0} />
        <Step selected={step > 1} />
        <Step selected={step > 2} />
        <Step selected={step > 3} />
        <Step selected={step > 4} />
      </Flex>
    </Flex>
  )
}

export const Order = ({ onClose, orderId, showSaveProgress, navigate }) => {
  const [pageIndex, setPageIndex] = useState(0)
  const [order, setOrder] = useState(null)
  const [comment, setComment] = useState(null)
  const [checkout, setCheckout] = useState(null)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation();

  const getTitle = (pageIndex) => {
    if (pageIndex === 0) {
      return ''
    } else if (pageIndex === 1) {
      return t('order_text_1')
    } else if (pageIndex === 2) {
      return t('order_text_2')
    } else if (pageIndex === 3) {
      return t('order_text_3')
    } else if (pageIndex === 4) {
      return t('order_text_4')
    } else if (pageIndex === 5) {
      return t('order_text_5')
    }
  }


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pageIndex])

  useEffect(() => {
    if (orderId) {
      setLoading(true)
    }
  }, [orderId])

  if (loading) {
    return (
      <Flex
        bg={'white'}
        width={'100%'}
        height={'90vh'}
        flexDirection={'column'}
        alignItems={'center'}
        style={{ overflow: 'hidden' }}>
        <Loading isLoading={true} />
      </Flex>
    )
  }

  return (
    <Flex
      bg={'white'}
      width={'100%'}
      pt={10}
      pb={3}
      pr={3}
      pl={3}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      style={{ overflow: 'hidden' }}>
      {showSaveProgress ? (
        <SaveOrder orderId={order ? order.id : 'noid'} />
      ) : (
        <Flex
          width={'100%'}
          maxWidth={pageIndex === 6 ? '730px' : '1000px'}
          flexDirection={'column'}
          alignItems={'center'}
          mb={10}
          style={{ overflow: 'hidden' }}>
          {pageIndex === 0 && (
            <Start
              title={t('start_title')}
              info={t('start_text_alt')}
              orderId={order ? order.id : null}
              start={(orderId) => {
                setOrder({ ...order, id: orderId })
                setPageIndex(pageIndex + 1)
              }}
              close={onClose}
            />
          )}

          {pageIndex === 6 && (
            <Basket
              changeRoom={(rooms) => {
                setOrder({ ...order, rooms: rooms })
                if (rooms.length === 0) {
                  setPageIndex(1)
                }
              }}
              changeOption={(option) => {
                setOrder({ ...order, productOption: option })
              }}
              checkout={checkout}
              rooms={order.rooms}
              orderId={order.id}
              productOption={order.productOption}
              backClick={() => {
                setPageIndex(pageIndex - 1)
              }}
            />
          )}

          {pageIndex !== 0 && pageIndex !== 6 && (
            <>
              <Stepper step={pageIndex} />
              <Text
                textAlign={'start'}
                width={'100%'}
                mt={[4, 4, 7]}
                mb={[2, 2, 4]}
                fontSize={'22px'}>
                {getTitle(pageIndex)}
              </Text>

              <>
                {pageIndex === 1 && (
                  <Option
                    option={
                      order && order.productOption ? order.productOption : ''
                    }
                    backClick={() => {
                      setPageIndex(pageIndex - 1)
                    }}
                    orderId={order.id}
                    next={(option) => {
                      setOrder({ ...order, productOption: option })
                      setPageIndex(pageIndex + 1)
                    }}
                  />
                )}
                {pageIndex === 2 && (
                  <ChooseRoom
                    rooms={order && order.rooms ? order.rooms : []}
                    backClick={() => {
                      setPageIndex(pageIndex - 1)
                    }}
                    orderId={order.id}
                    next={(rooms) => {
                      setOrder({ ...order, rooms: rooms })
                      setPageIndex(pageIndex + 1)
                    }}
                  />
                )}

                {pageIndex === 3 && (
                  <ChooseFurnishings
                    backClick={() => {
                      setPageIndex(pageIndex - 1)
                    }}
                    whatAppeal={order.whatAppeal}
                    orderId={order.id}
                    next={(whatAppeal) => {
                      setOrder({ ...order, whatAppeal: whatAppeal })
                      setPageIndex(pageIndex + 1)
                    }}
                  />
                )}
                {pageIndex === 4 && (
                  <Budget
                    backClick={() => {
                      setPageIndex(pageIndex - 1)
                    }}
                    initalType={order.budgetType}
                    initialPrice={order.budget}
                    rooms={order.rooms}
                    orderId={order.id}
                    next={() => {
                      setPageIndex(pageIndex + 1)
                    }}
                    info={t('budget_text_1')}
                  />
                )}
                {pageIndex === 5 && (
                  <Message
                    info={t('message_text_1')}
                    backClick={() => {
                      setPageIndex(pageIndex - 1)
                    }}
                    rooms={order.rooms}
                    orderId={order.id}
                    initialComment={comment}
                    next={async (com) => {
                      const re = await createCheckout({
                        rooms: order.rooms,
                        orderId: order.id,
                        lang: t('language'),
                        createCheckoutError: t('create_checkout_text')
                      })
                      if (re && re.checkout) {
                        setComment(com)
                        setCheckout(re.checkout)
                        setPageIndex(pageIndex + 1)
                      }
                    }}
                  />
                )}
              </>
            </>
          )}
        </Flex>
      )}
    </Flex>
  )
}
