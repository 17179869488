import React, { useState } from 'react'
import { Flex, Text } from '../primitives'
import { BackAndNext } from './components/BackAndNext'
import { OptionCard } from './components/OptionCard'
import { Check } from '@styled-icons/boxicons-regular'
import { addOptionToOrder } from '../../api/addOptionToOrder'
// import {
//   conceptPrice,
//   guidancePrice,
//   twoDPlanPrice
// } from '../../helpers/config'
import { useTranslation } from "react-i18next";

export const Item = ({ title }) => {
  return (
    <Text>
      <Check size='25px' style={{ marginBottom: '5px', marginRight: '5px' }} />
      {title}
    </Text>
  )
}

export const Option = ({ orderId, backClick, next, option }) => {
  const [selected, setSelected] = useState(option)
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation();
  const gprice = t('guidancePrice') + ' ' + t('currency');
  const twoprice = t('twoDPlanPrice') + ' ' + t('currency');
  const cprice = t('conceptPrice') + ' ' + t('currency');

  return (
    <>
      <Flex
        width={'100%'}
        flexWrap={'wrap'}
        style={{
          overflowY: 'auto'
        }}
        flex={1}>
        <Text
          textAlign={'start'}
          width={'100%'}
          mb={[2, 2, 4]}
          fontSize={'18px'}>
          {t('option_text_1')}
        </Text>
        <Flex
          width={'100%'}
          flexDirection={'column'}
          flexWrap={'wrap'}
          style={{ overflowY: 'auto', alignContent: 'flex-start' }}>
          <Flex width={'100%'} flexWrap={'wrap'} jusifyContent={'center'}>
            <OptionCard
              title={t('basket_get_basket_title_1')}
              price={gprice}
              selected={selected === 'guidance'}
              onClick={() => {
                setSelected('guidance')
              }}>
              <Flex flexDirection={'column'} width={'100%'}>
                <Text mb={2}>
                  {t('option_text_1')}
                </Text>
                <Item title={t('option_text_3')} />
                <Item title={t('option_text_4')} />
              </Flex>
            </OptionCard>
            <OptionCard
              title={t('basket_get_basket_title_2')}
              price={twoprice}
              selected={selected === '2d-plan'}
              onClick={() => {
                setSelected('2d-plan')
              }}>
              <Flex flexDirection={'column'} width={'100%'}>
                <Text mb={5}>
                  {t('option_text_5')}
                </Text>
                <Item title={t('option_text_6')} />
                <Item title={t('option_text_7')} />
              </Flex>
            </OptionCard>
            <OptionCard
              title={t('option_text_8')}
              selected={selected === 'concept'}
              price={cprice}
              onClick={() => {
                setSelected('concept')
              }}>
              <Flex flexDirection={'column'} width={'100%'}>
                <Text pr={2} pl={2} mb={2}>
                  {t('option_text_9')} 
                </Text>
                <Item title={t('option_text_3')} />
                <Item title={t('option_text_4')} />
                <Item title={t('option_text_10')} />
                <Item title={t('option_text_7')} />
                <Item title={t('business_space_count_info_text_5')} /> 
              </Flex>
            </OptionCard>
          </Flex>
        </Flex>
      </Flex>
      <BackAndNext
        canClickNext={selected.length > 0}
        isLoading={isLoading}
        backClick={backClick}
        nextClick={async () => {
          if (selected.length > 0) {
            setIsLoading(true)
            await addOptionToOrder({ orderId: orderId, option: selected, lang: t('language'), addOptionError: t('add_option_text') })
            setIsLoading(false)
            next(selected)
          }
        }}
      />
    </>
  )
}
