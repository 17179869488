import React, { useState } from 'react'
import { Flex, Text } from '../../primitives'

export const ButtonPicker = ({
  titleOne,
  titleTwo,
  titleThree,
  onSelected,
  initalOneValue = 'guidance',
  mb = [8, 5, 2]
}) => {
  const [selected, setSelected] = useState(initalOneValue)
  return (
    <Flex height={'40px'} alignItems={'center'} mb={mb}>
      <Flex
        onClick={() => {
          setSelected('guidance')
          onSelected('guidance')
        }}
        style={{
          cursor: 'pointer',
          zIndex: selected === 'guidance' ? 1000 : 0,
          boxShadow:
            selected === 'guidance'
              ? '0px 6px 16px -4px rgba(0,0,0,0.22)'
              : 'none'
        }}
        bg={selected === 'guidance' ? 'white' : 'grey'}
        height={'40px'}
        color={selected === 'guidance' ? 'black' : 'secondaryLight'}
        width={'80px'}
        alignItems={'center'}
        jusifyContent={'center'}>
        <Text>{titleOne}</Text>
      </Flex>
      <Flex
        onClick={() => {
          setSelected('2d-plan')
          onSelected('2d-plan')
        }}
        style={{
          cursor: 'pointer',
          zIndex: selected === '2d-plan' ? 1000 : 0,
          boxShadow:
            selected === '2d-plan'
              ? '0px 6px 16px -4px rgba(0,0,0,0.22)'
              : 'none'
        }}
        bg={selected === '2d-plan' ? 'white' : 'grey'}
        color={selected === '2d-plan' ? 'black' : 'secondaryLight'}
        height={'40px'}
        width={'80px'}
        alignItems={'center'}
        jusifyContent={'center'}>
        <Text>{titleTwo}</Text>
      </Flex>
      <Flex
        onClick={() => {
          setSelected('concept')
          onSelected('concept')
        }}
        style={{
          cursor: 'pointer',
          zIndex: selected === 'concept' ? 1000 : 0,
          boxShadow:
            selected === 'concept'
              ? '0px 6px 16px -4px rgba(0,0,0,0.22)'
              : 'none'
        }}
        bg={selected === 'concept' ? 'white' : 'grey'}
        color={selected === 'concept' ? 'black' : 'secondaryLight'}
        height={'40px'}
        width={'120px'}
        alignItems={'center'}
        jusifyContent={'center'}>
        <Text>{titleThree}</Text>
      </Flex>
    </Flex>
  )
}
