import { Store } from 'react-notifications-component'

export const ShowError = ({ title = 'Hoppsan, Något gick fel.', message }) => {
  Store.addNotification({
    title: title,
    message: message,
    type: 'danger',
    insert: 'top',
    container: 'top-left',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 5000
    }
  })
}


export const ShowInfo = ({ title = '', message }) => {
  Store.addNotification({
    title: title,
    message: message,
    type: 'info',
    insert: 'top',
    container: 'top-left',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 5000
    }
  })
}
