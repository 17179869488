import React, { useState } from 'react'
import { Flex, Text } from '../primitives'
import { Range } from 'react-range'
import { colors } from '../../other/colors/color'
import { BackAndNext } from './components/BackAndNext'
import { addBudgetToOrder } from '../../api/addBudgetToOrder'
import { formatNumber } from 'accounting-js'
import { useTranslation } from "react-i18next";


export const Budget = ({
  orderId,
  backClick,
  next,
  initalType,
  initialPrice,
  // min = 5000,
  // max = 100000,
  // step = 500,
  info
}) => {
  const { t } = useTranslation();
  const getInitialPrice = () => {
    return t('budget_initial_price');
  };

  const [budgetType] = useState(initalType ? initalType : 'flexibel')
  const [price, setPrice] = useState([initialPrice ? initialPrice : getInitialPrice()])
  const [isLoading, setIsLoading] = useState(false)
  const min = t('budget_min')
  const max = t('budget_max')
  const step = t('budget_step')

  return (
    <>
      <Flex
        width={'100%'}
        flexWrap={'wrap'}
        flex={1}
        style={{ overflowY: 'auto', alignContent: 'flex-start' }}>
        <Flex width={'100%'} flexDirection={'column'}>
          <Text mb={8} fontSize={'18px'}>
            {info}
          </Text>

          <div>
            <Flex mb={4} jusifyContent={'center'}>
              <Text
                bg={'primary'}
                color={'white'}
                style={{ borderRadius: '25px' }}
                mb={2}
                p={2}
                textAlign={'center'}>
                {formatNumber(price, { precision: 0, thousand: '.' })} {t('currency')}
              </Text>
            </Flex>
          </div>
          <Flex height={'60px'} alignItems={'center'}>
            <Text width={'100px'} mr={2}>
              {formatNumber(min, { precision: 0, thousand: '.' })} {t('currency')}
            </Text>
            <Range
              step={step}
              min={min}
              max={max}
              values={price}
              onChange={(values) => setPrice(values)}
              renderTrack={({ props, children }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: '6px',
                    width: '100%',
                    borderRadius: '5px',
                    backgroundColor: '#ccc'
                  }}>
                  {children}
                </div>
              )}
              renderThumb={({ props }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: '25px',
                    width: '25px',
                    borderRadius: '50%',
                    backgroundColor: colors.primary
                  }}
                />
              )}
            />
            <Text width={'120px'} ml={2}>
              {formatNumber(max, { precision: 0, thousand: '.' })} {t('currency')}
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <BackAndNext
        canClickNext={true}
        isLoading={isLoading}
        backClick={backClick}
        nextClick={async () => {
          setIsLoading(true)
          await addBudgetToOrder({
            orderId: orderId,
            budgetType: budgetType,
            budget: price[0],
            budgetError: t('add_budget_order_text')
          })
          //const re = await createCheckout({ rooms, orderId })
          next()
          /*if (re && re.checkout) {
            next()
          } */
        }}
      />
    </>
  )
}
