import { Text as ReText } from 'rebass/styled-components'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { display } from 'styled-system'

export const Text = styled(ReText)`
  ${display}
`

Text.defaultProps = {
  fontFamily: 'main',
  color: 'dark',
  fontWeight: 400
}

Text.propTypes = {
  fontWeight: PropTypes.any,
  textAlign: PropTypes.any
}
