export const colors = {
  primary: '#9a7652', // Guld
  primaryLight: '#c9b49e', // Sand
  primaryDark: '#6b4c3b', // Koppar
  secondary: '#b9bbb0', // Kalk
  secondaryLight: '#798675', // SilverBuske
  secondaryDark: '#636752', // Mossa
  dark: '#262829', // Järn
  white: '#fff',
  grey: '#FAFAFA'
}
