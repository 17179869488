import React, { useEffect, useState } from 'react'
import { Flex } from '../components/primitives'
import { Basket } from '../components/order/Basket'
import { getOrderFromKlarnaId } from '../api/getOrderFromKlarnaId'
import { LoadingSpinner } from '../components/shareable/lottie/LoadingSpinner'
import { useTranslation } from "react-i18next";

export const Checkout = () => {
  const [loading, setLoading] = useState(false)
  const [order, setOrder] = useState(false)
  const [checkout, setCheckout] = useState(false)
  const { t } = useTranslation();
  const language = t('language')

  useEffect(() => {
    const klarnaId = new URLSearchParams(window.location.search).get('klarnaId')
    const getOrder = async () => {
      setLoading(true)
      const respone = await getOrderFromKlarnaId({ klarnaId, language })
      console.log(respone)
      setOrder({ _id: respone.order._id, ...respone.order.customerOrder })
      setCheckout(respone.checkout)
      setLoading(false)
    }

    getOrder()
  }, [])

  return (
    <Flex jusifyContent={'center'} width={'100%'}>
      <Flex
        mt={['80px', '80px', '120px']}
        maxWidth={'1000px'}
        width={'100%'}
        minHeight={'100vh'}
        p={4}
        flexDirection={'column'}>
        {loading && (
          <Flex mt={4} width={'100%'} jusifyContent={'center'} height={'40px'}>
            <Flex width={'100px'} height={'40px'}>
              <LoadingSpinner />
            </Flex>
          </Flex>
        )}
        {checkout && (
          <Basket
            showBackButton={false}
            changeRoom={(rooms) => {
              setOrder({ ...order, rooms: rooms })
            }}
            changeOption={(option) => {}}
            checkout={checkout}
            rooms={order.rooms}
            orderId={order._id}
            backClick={() => {}}
          />
        )}
      </Flex>
    </Flex>
  )
}
