import React from 'react'
import { Order } from './components/order/Order'
import { Normalize } from 'styled-normalize'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import logoBlack from './images/hemset-black.png'

import "./i18n"
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createBrowserRouter,
  RouterProvider,
  redirect
} from "react-router-dom";


import theme from './other/theme'
import { Flex, ImageContain } from './components/primitives'
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { BrowserRouter as Route, Navigate } from 'react-router-dom'
import { Confirmation } from './pages/Confirmation'
import { Checkout } from './pages/Checkout'
import {BusinessOrder} from "./components/order/BusinessOrder";
const GlobalStyle = createGlobalStyle`
      * {
      font-family: futura-pt, futura, sans-seriff;
    }

#klarna-checkout-container{
width: 340px;

}

@media only screen and (max-width: 960px) {
 #klarna-checkout-container{
  width: 100%; }  }
`

const router = createBrowserRouter([
  {
    path: "/:lng",
    children:[
      {
        path:'/:lng/confirmation',
        element: <Confirmation/>
      },
      {
        path:'/:lng/checkout',
        element: <Checkout/>
      },
      {
        path:'/:lng/',
        element: <Order/>
      }
    ],

  },
  {
    path: "*",
    element: <Navigate to="/se" replace />,

  },
]);


function App() {
  const { i18n } = useTranslation();
  return (
    <ThemeProvider theme={theme}>
      <>
        <Normalize />
        <GlobalStyle />
        <ReactNotifications />
        <Suspense fallback='loading'>
          <RouterProvider router={router} />
        {/* <Router> */}
          {/* <Flex
            ml={2}
            style={{ position: 'absolute' }}
            onClick={() => {
              window.location.href = 'https://hemset.se'
            }}>
           <ImageContain height={'60px'} width={'150px'} src={logoBlack} />
          </Flex>*/}

          {/* <Switch>
            <Route path='/confirmation'>
              <Confirmation />
            </Route>
            <Route path='/checkout'>
              <Checkout />
            </Route>
            <Route path='/business'>
              <BusinessOrder />
            </Route>
            <Route path='/'>
              <Order />
            </Route>
          </Switch>
        </Router> */}
      </Suspense>
      </>
    </ThemeProvider>
  )
}

export default App
