import axios from 'axios'
import { ShowError } from '../helpers/showError'

export const addWhatAppealToOrder = async ({ orderId, whatAppeal, whatAppealError }) => {
  try {
    const url = process.env.REACT_APP_API_URL + '/addWhatAppealToOrder'
    const response = await axios({
      method: 'post',
      url: url,
      data: {
        orderId,
        whatAppeal
      },
      headers: {
        'content-type': 'application/json'
      }
    })

    console.log(response.data)
    return response.data
  } catch (e) {
    console.log('erororo', e.response.data)
    ShowError({
      message: whatAppealError
    })
  }
}
