import styled from 'styled-components'
import { Button as REButton } from 'rebass/styled-components'
import { darken } from 'polished'
import { colors } from '../../other/colors/color'

const Button = styled(REButton)`
  position: ${(props) => props.position};
  min-width: ${(props) => props.minWidth};
  top: ${(props) => props.top};
  color: ${colors.white}
  bottom: ${(props) => props.bottom};
  font-weight: 500;
  border-radius: 5px;
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  cursor: pointer;
  &:hover {
    background-color: ${darken(0.05, 'white')};
  }
  &:focus {
    outline: none;
  }
`

Button.defaultProps = {
  bg: 'white',
  color: 'black',
  height:"46px",
  minWidth: '180px'
}

export { Button }
