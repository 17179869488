import axios from 'axios'
import { ShowError } from '../helpers/showError'

export const startOrder = async (startOrderError) => {
  console.log(process.env)
  try {
    const url = process.env.REACT_APP_API_URL + '/startOrder'
    const response = await axios({
      method: 'post',
      url: url,
      headers: {
        'content-type': 'application/json'
      }
    })

    return response ? response.data : null
  } catch (e) {
    console.log('erororo', e.response.data)
    ShowError({
      message: startOrderError
    })
  }
}
