export const en = {
  "start_title": "Welcome!",
  "start_text": "You will now go through three short steps to describe the interior design project. What is the business about, what do you need help with, and what are the conditions and preferences? Once the order is completed, one of our designers will contact you to start the project. This usually happens within 1-3 working days.",
  "start_text_alt": "You will now go through a few short steps where you describe what you need help with, as well as the conditions and preferences for the interior design project. Once the order is completed, one of our designers will contact you to start the project. This will happen within 1-4 working days. Thank you for your trust!",
  "start_button": "Let's get started",
  "basket_regular_text": "After completing the purchase, you will receive an order confirmation, and within 1-4 working days, one of our designers will contact you to start your interior design project.",
  "basket_concept_text": "After completing the purchase, one of our designers will contact you to start the project.",
  "basket_concept_text_title": "This is included in the COMPLETE service:",
  "basket_concept_text_title_biz": "This is included in the BUSINESS service:",
  "basket_concept_text_li_1": "Professional interior assistance.",
  "basket_concept_text_li_2": "Dialogue with the designer",
  "basket_concept_text_li_3": "Mood board for the project",
  "basket_concept_text_li_4": "Floor plan of the room",
  "basket_concept_text_li_5": "2D/3D visualization",
  "basket_concept_text_li_6": "Digital product list",
  "basket_concept_text_li_7": "Continuous alignment",
  "basket_concept_text_li_8": "Includes one revision",
  "basket_concept_li_end_text": "Read about our Satisfied Customer Guarantee",
  "basket_guide_text_1": "After completing the purchase, our designer will contact you to start the project.",
  "basket_guide_text_2": "This is included in the TIPS & ADVICE service:",
  "basket_guide_text_li_1": "Professional interior assistance",
  "basket_guide_text_li_2": "30-minute conversation with the designer",
  "basket_guide_text_li_3": "Digital product list",
  "basket_guide_text_3": "Read about our Satisfied Customer Guarantee",
  "basket_guide_text_4": "here",
  "basket_get_basket_title_1": "Consultation",
  "basket_get_basket_title_2": "2D Plan",
  "basket_get_basket_title_3": "3D Visualization",
  "basket_get_basket_text_1": "Cart",
  "basket_get_basket_text_2": "Add additional rooms",
  "basket_get_basket_text_3": "Add a gift code",
  "basket_get_basket_text_4": "gift code added!",
  "basket_get_basket_dscode": "Gift code",
  "basket_get_basket_text_5": "Add",
  "basket_get_basket_button": "Back",
  "business_order_get_title_text_1": "Description of the interior design project",
  "business_order_get_title_text_2": "How large is the space to be furnished?",
  "business_order_get_title_text_3": "What is the budget like?",
  "business_order_business_order_message_info_1": "Briefly describe the type of assistance needed. What is the business about? What are the needs and preferences, and what are the conditions?",
  "business_order_business_order_message_info_2": "Of course, it is possible to reuse as much of the furniture and interior as you already have. We provide tips on how to renew and reuse existing furniture and interior, as well as suggestions for new purchases. There is no obligation to buy new products, and the interior design service always has a fixed price.",
  "business_space_count_info_text_title": "Get a design proposal on how to color, furnish, and decorate your premises. We help you create an atmosphere that makes customers and employees long to return.",
  "business_space_count_info_text_1": "Dialogue with the designer",
  "business_space_count_info_text_2": "Mood board for the project",
  "business_space_count_info_text_3": "Customization for your business",
  "business_space_count_info_text_4": "Floor plan with furniture arrangement",
  "business_space_count_info_text_5": "2D/3D visualization",
  "business_space_count_info_text_6": "Digital product list",
  "business_space_count_info_text_7": "Includes one revision",
  "business_space_count_bsp_text_1": "Add the number of rooms or spaces (part of a room) that you need help with for furnishing and decorating. A room/space can be up to 40 sqm. If the room or part of the room is larger than that, add an additional room/space. If you are unsure how to proceed, contact us at",
  "business_space_count_bsp_text_2": "and we will assist you.",
  "business_space_count_bsp_text_3": "Number of rooms/spaces",
  "choose_furnishings_text_1": "Give your designer a good start on the project by telling them which style appeals to you. You can choose one or several images.",
  "choose_furnishings_text_2": "You can also link to a Pinterest board",
  "choose_furnishings_text_3": "Pinterest, for example, insert a link to a board",
  "choose_image_step_text_1": "Scandinavian",
  "choose_image_step_text_2": "Modern",
  "choose_image_step_text_3": "Classic",
  "choose_room_text_1": "Number",
  "choose_room_text_2": "Add",
  "choose_room_text_3_1": "Add the room or space (part of a room) that you need help with for furnishing and decorating. A room/space can be up to 30 sqm. If the room or part of the room is larger than that, add an additional room/space. If you are unsure how to proceed, contact us at",
  "choose_room_text_3_2": "and we will assist you.",
  "choose_room_text_4": "Workspace",
  "choose_room_text_5": "Outdoor area",
  "choose_room_text_6": "Hallway",
  "choose_room_text_7": "Kitchen",
  "choose_room_text_8": "Dining area",
  "choose_room_text_9": "Bedroom",
  "choose_room_text_10": "Living room",
  "choose_room_text_11": "Other",
  "contact_text_1": "Fill in your information, and we will contact you to discuss which interior design will suit your home. Together, you and our designer will go through how your existing interior can be adapted to the new one.",
  "contact_text_2": "First and last name",
  "contact_text_3": "Phone number",
  "contact_text_4": "Email",
  "contact_text_5": "Comment (optional)",
  "contact_text_6": "Please enter your username on Instagram and Pinterest so that we can get to know you better.",
  "contact_text_7": "Instagram (optional)",
  "contact_text_8": "Pinterest (optional)",
  "file_step_text_1": "Could not upload the file",
  "file_step_text_2": "Please try again later",
  "file_step_text_3": "Remove",
  "file_step_text_4": "When filming, please move slowly and walk around the room. Make sure the ceiling height is visible! The ideal length of the video is 15-20 seconds. Feel free to share your thoughts on the room in the video.",
  "file_step_text_5": "Feel free to add more pictures to show more details.",
  "file_step_text_6": "If you have a new property, you can also upload brochures, floor plans, and pictures from viewings.",
  "file_step_text_7": "Add video ",
  "file_step_text_8": "or image",
  "file_step_text_9": "Are you sure you don't want to submit any images?",
  "file_step_text_10": "Next",
  "file_step_text_11": "Yes",
  "info_step_text_1": "What are you missing?",
  "info_step_text_2": "Decorations",
  "info_step_text_3": "Furniture",
  "info_step_text_4": "Mixed",
  "info_step_text_5": "Open to suggestions",
  "info_step_text_6": "Who will use the room?",
  "info_step_text_7": "Please describe if the room is intended for specific individuals (e.g., a child, an adult) or if it is a ",
  "info_step_text_8": "general-purpose room",
  "info_step_text_9": "Description",
  "info_step_text_10": "What colors do you like?",
  "info_step_text_11": "Please list some colors you like so that our designer can make a better choice",
  "option_text_1": "If you want to order multiple rooms with different services, you can place an order with x number of rooms per service type. All our services have a fixed price and are carried out entirely online.",
  "option_text_2": "Receive verbal tips and advice on how to furnish your room, as well as a mood board to help you create the style and atmosphere you desire.",
  "option_text_3": "Conversation with the designer (approx. 30 minutes)",
  "option_text_4": "Mood board for the room",
  "option_text_5": "Get a clear proposal on how to furnish your existing or new home based on your needs and preferences.",
  "option_text_6": "Floor plan of the room",
  "option_text_7": "Digital product list",
  "option_text_8": "3D visualization",
  "option_text_9": "Receive a complete proposal on how to furnish and decorate to create the atmosphere and functions you desire in your home.",
  "option_text_10": "Furniture layout plan",
  "order_text_1": "What type of assistance do you need?",
  "order_text_2": "Which space do you need help with?",
  "order_text_3": "Which style do you like?",
  "order_text_4": "Budget for new product purchases?",
  "order_text_5": "Message to the designer",
  "budget_text_1": "What is your budget for new purchases? Of course, you can reuse as much as you want from what you already have at home. There is no obligation to buy new products, and the interior design service always has a fixed price.",
  "message_text_1": "Please briefly describe what you need help with. What are your needs and preferences, and what are the conditions? Once you are in contact with your designer, you can send pictures and videos of the space you need assistance with.",
  "save_order_1": "Save your selections?",
  "save_order_2": "Sent!",
  "save_order_3": "Enter your email to receive a link to return to your selections",
  "save_order_4": "Save",
  "add_budget_order_text": "Could not add the budget. Please try again later",
  "add_comment_order_text": "Could not add the comment. Please try again later",
  "add_discount_message_text_info": "Could not find the discount code. Please try another one",
  "add_discount_message_text_err": "Could not add the discount code. Please try again later",
  "add_files_text": "Could not add the files. Please try again later",
  "add_option_text": "Could not create the order. Please try again later",
  "add_pinterest_text": "Could not add Pinterest. Please try again later",
  "add_room_text": "Could not add/remove rooms to the order. Please try again later",
  "add_whatappeal_text": "Could not add What style appeals to you best. Please try again later",
  "add_whatstyle_text": "Could not add What style appeals to you best. Please try again later",
  "create_checkout_text": "Could not create the payment. Please try again later",
  "remove_file_text": "Could not remove the file. Please try again later",
  "send_save_text": "Could not create the link. Please try again later",
  "start_order_text": "Could not create the order. Please try again later",
  "update_checkout_text": "Could not update. Please try again later",
  "add_comment_text_1": "Message to the designer",
  "add_pinterest_text_1": "Pinterest saved!",
  "confirmation_text_1": "Thank you for choosing Hemset!",
  "confirmation_text_2": "We look forward to helping you get a comfortable home!",
  "confirmation_text_3": "An interior designer will contact you within 1-4 days.",
  "currency": "USD",
  "language": "en",
  "guidancePrice": "99",
  "twoDPlanPrice": "299",
  "conceptPrice": "399",
  "businessPrice": "550",
  "budget_min": 500,
  "budget_max": 10000,
  "budget_step": 50,
  "budget_initial_price": 1000
}
