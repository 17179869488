import React, { useState } from 'react'
import { Flex, Text } from '../primitives'
import { TextWithClick } from '../shareable/text/TextWithHover'
import { Loading } from '../shareable/loading/loading'
import { startOrder } from '../../api/startOrder'
import { ButtonPrimary } from '../shareable/buttons/ButtonPrimary'
import { useTranslation } from "react-i18next";


export const Start = ({ orderId, start, close, title, info }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation();

  return (
    <Flex flexDirection={'column'} height={'100%'}>
      <Flex
        width={'100%'}
        height={'100%'}
        flexDirection={'column'}
        alignItems={'center'}
        jusifyContent={'center'}>
        <Text mb={[4, 6, 8]} fontSize={'32px'}>
          {title}
        </Text>
        <Text
          mb={[5, 10, 10]}
          width={'100%'}
          fontSize={'18px'}
          style={{ lineHeight: '26px' }}>
          {info}
        </Text>
        <Loading isLoading={isLoading} mb={4}>
          <ButtonPrimary
            onClick={async () => {
              if (orderId) {
                start(orderId)
              } else {
                setIsLoading(true)
                const data = await startOrder(t('start_order_text'))
                if (data) {
                  start(data.orderId)
                }
              }
            }}
            mb={4}>
            {t('start_button')}
          </ButtonPrimary>
        </Loading>
        {/*<TextWithClick
          onClick={() => {
            window.location.href = 'https://hemset.se'
          }}
          color={'primaryLight'}>
          Gå tillbaka
        </TextWithClick> */}
      </Flex>
    </Flex>
  )
}
