import React, { useState } from 'react'
import { Flex, Text } from '../primitives'
import { Range } from 'react-range'
import { colors } from '../../other/colors/color'
import { BackAndNext } from './components/BackAndNext'
import { addBudgetToOrder } from '../../api/addBudgetToOrder'
import { createCheckout } from '../../api/createCheckout'
import { AddComment } from './components/AddComment'
import { addCommentToOrder } from '../../api/addCommentToOrder'
import { useTranslation } from "react-i18next";


export const Message = ({
  orderId,
  backClick,
  next,
  rooms,
  info,
  initialComment = null
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [comment, setComment] = useState(initialComment)
  const { t } = useTranslation();

  return (
    <>
      <Flex
        width={'100%'}
        flexWrap={'wrap'}
        flex={1}
        style={{ overflowY: 'auto', alignContent: 'flex-start' }}>
        <Flex width={'100%'} flexDirection={'column'}>
          <Text mb={8} fontSize={'18px'}>
            {info}
          </Text>

          <Flex flexDirection={'column'} flex={1}>
            <AddComment
              orderId={orderId}
              comment={comment}
              onCommentWrite={(c) => {
                setComment(c)
              }}
            />
          </Flex>
        </Flex>
      </Flex>

      <BackAndNext
        canClickNext={true}
        isLoading={isLoading}
        backClick={backClick}
        nextClick={async () => {
          setIsLoading(true)

          if (comment) {
            const c = await addCommentToOrder({
              orderId: orderId,
              comment: comment,
              commentError: t('add_comment_order_text')
            })
            if (c) {
            }
          }

          next(comment)

          /*
          const re = await createCheckout({ rooms, orderId })
          setIsLoading(false)
          if (re && re.checkout) {
            next(re.checkout, comment)
          } */
        }}
      />
    </>
  )
}
