import { Flex } from '../../primitives'
import { TextArea } from '../../shareable/form/Input'
import React from 'react'
import { useTranslation } from "react-i18next";

export const AddComment = ({ orderId, onCommentWrite, comment }) => {
  const { t } = useTranslation();
  return (
    <Flex flexDirection={'column'} p={4}>
      <Flex flexDirection={'column'}>
        <Flex>
          <TextArea
            rows={10}
            placeholder={t('add_comment_text_1')}
            value={comment ? comment : ''}
            onChange={(e) => {
              onCommentWrite(e.target.value)
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
