import React, { useState } from 'react'
import { Button, Flex, ImageContain, Text } from '../primitives'
import style1 from '../../images/Arbetsrum.svg'
import style2 from '../../images/Balkong.svg'
import style3 from '../../images/Hall.svg'
import style4 from '../../images/Kök.svg'
import style5 from '../../images/Konferansrum.svg'
import style6 from '../../images/Sovrum.svg'
import style7 from '../../images/Vardagsrum.svg'

import styled from 'styled-components'
import { CheckCircle } from '@styled-icons/boxicons-solid/CheckCircle'
import { Circle } from '@styled-icons/boxicons-regular/Circle'
import { BackAndNext } from './components/BackAndNext'
import { addRoomToOrder } from '../../api/addRoomToOrder'
import { Input } from '../shareable/form/Input'
import { ButtonPrimaryLight } from '../shareable/buttons/ButtonPrimaryLight'
import { useTranslation } from "react-i18next";

const styleImage = {
  borderRadius: '10px',
  height: '100px',
  width: '100px',
  marginBottom: '15px',
  marginTop: '20px'
}

const FlexWithCorner = styled(Flex)`
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    border-color: transparent;
    border-style: solid;
  }
  &:before {
    border-width: 1.5em;
    border-right-color: #ccc;
    border-top-color: #ccc;
  }
  &:after {
    border-width: 2em;
    border-bottom-right-radius: ${(props) => (props.selected ? '1px' : '2px')};
    border-right-color: ${(props) => (props.selected ? '#9a7652' : 'white')};
    border-bottom-color: ${(props) => (props.selected ? '#9a7652' : 'white')};
  }
`

const RoomHolder = ({ children, selected, onClick }) => {
  const [showInput, setShowInput] = useState(false)
  const [quantity, setQuantity] = useState(1)
  const { t } = useTranslation();

  return (
    <Flex width={['50%', '25%', '25%']} p={1}>
      {showInput ? (
        <Flex
          flexDirection={'column'}
          p={4}
          width={'100%'}
          alignItems={'center'}>
          <Text mb={1}>{t('choose_room_text_1')}</Text>
          <Input
            value={quantity}
            onChange={(e) => {
              setQuantity(e.target.value)
            }}
            placeholder={'Quantity'}
          />

          <ButtonPrimaryLight
            minWidth={'100%'}
            mt={2}
            onClick={() => {
              setShowInput(false)
              onClick(quantity)
            }}>
            {t('choose_room_text_2')}
          </ButtonPrimaryLight>
        </Flex>
      ) : (
        <FlexWithCorner
          onClick={() => {
            if (selected) {
              onClick(0)
            } else {
              setShowInput(true)
            }
          }}
          selected={selected}
          flexDirection={'column'}
          alignItems={'center'}
          width={'100%'}
          height={'190px'}
          style={{
            cursor: 'pointer',
            border: '1px solid #BABAB1',
            borderRadius: '2px'
          }}>
          {children}
          {selected ? (
            <Flex
              color={'white'}
              style={{
                right: '5px',
                bottom: '8px',
                position: 'absolute',
                zIndex: 12
              }}
              width={'100%'}
              jusifyContent={'flex-end'}>
              <CheckCircle size={'20px'} />
            </Flex>
          ) : (
            <Flex
              color={'lightGrey'}
              style={{
                right: '5px',
                bottom: '8px',
                position: 'absolute',
                zIndex: 12
              }}
              width={'100%'}
              jusifyContent={'flex-end'}>
              <Circle size={'20px'} />
            </Flex>
          )}
        </FlexWithCorner>
      )}
    </Flex>
  )
}

export const ChooseRoom = ({ rooms, orderId, backClick, next }) => {
  const [selected, setSelected] = useState(rooms)
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation();

  const selectRoom = ({ room, quanity }) => {
    const r = []
    for (let i = 0; i < quanity; i++) {
      r.push(room)
    }
    setSelected([...selected, ...r])
  }

  return (
    <>
      <Flex
        width={'100%'}
        flexWrap={'wrap'}
        style={{
          overflowY: 'auto',
          alignContent: 'flex-start'
        }}
        flex={1}>
        <Text
          textAlign={'start'}
          width={'100%'}
          mb={[2, 2, 4]}
          fontSize={'18px'}>
          {t('choose_room_text_3_1')}
          {" "}<a href='mailto:info@hemset.com'>info@hemset.com</a> {t('choose_room_text_3_2')}
        </Text>
        <RoomHolder
          selected={selected.includes('arbetsplats')}
          onClick={(quanity) => {
            if (selected.includes('arbetsplats')) {
              const s = selected.filter((s) => s !== 'arbetsplats')
              setSelected(s)
            } else {
              selectRoom({ room: 'arbetsplats', quanity: quanity })
            }
          }}>
          <ImageContain style={styleImage} src={style1} />
          <Text flex={1}>{t('choose_room_text_4')}</Text>
        </RoomHolder>
        <RoomHolder
          selected={selected.includes('uteplats')}
          onClick={(quanity) => {
            if (selected.includes('uteplats')) {
              const s = selected.filter((s) => s !== 'uteplats')
              setSelected(s)
            } else {
              selectRoom({ room: 'uteplats', quanity: quanity })
            }
          }}>
          <ImageContain style={styleImage} src={style2} />
          <Text flex={1}>{t('choose_room_text_5')}</Text>
        </RoomHolder>
        <RoomHolder
          selected={selected.includes('hall')}
          onClick={(quanity) => {
            if (selected.includes('hall')) {
              const s = selected.filter((s) => s !== 'hall')
              setSelected(s)
            } else {
              selectRoom({ room: 'hall', quanity: quanity })
            }
          }}>
          <ImageContain style={styleImage} src={style3} />
          <Text flex={1}>{t('choose_room_text_6')}</Text>
        </RoomHolder>
        <RoomHolder
          selected={selected.includes('kök')}
          onClick={(quanity) => {
            if (selected.includes('kök')) {
              const s = selected.filter((s) => s !== 'kök')
              setSelected(s)
            } else {
              selectRoom({ room: 'kök', quanity: quanity })
            }
          }}>
          <ImageContain style={styleImage} src={style4} />
          <Text flex={1}>{t('choose_room_text_7')}</Text>
        </RoomHolder>
        <RoomHolder
          selected={selected.includes('matplats')}
          onClick={(quanity) => {
            if (selected.includes('matplats')) {
              const s = selected.filter((s) => s !== 'matplats')
              setSelected(s)
            } else {
              selectRoom({ room: 'matplats', quanity: quanity })
            }
          }}>
          <ImageContain style={styleImage} src={style5} />
          <Text flex={1}>{t('choose_room_text_8')}</Text>
        </RoomHolder>
        <RoomHolder
          selected={selected.includes('sovrum')}
          onClick={(quanity) => {
            if (selected.includes('sovrum')) {
              const s = selected.filter((s) => s !== 'sovrum')
              setSelected(s)
            } else {
              selectRoom({ room: 'sovrum', quanity: quanity })
            }
          }}>
          <ImageContain style={styleImage} src={style6} />
          <Text flex={1}>{t('choose_room_text_9')}</Text>
        </RoomHolder>
        <RoomHolder
          selected={selected.includes('vardagsrum')}
          onClick={(quanity) => {
            if (selected.includes('vardagsrum')) {
              const s = selected.filter((s) => s !== 'vardagsrum')
              setSelected(s)
            } else {
              selectRoom({ room: 'vardagsrum', quanity: quanity })
            }
          }}>
          <ImageContain flex={1} style={styleImage} src={style7} />
          <Text flex={1}>{t('choose_room_text_10')}</Text>
        </RoomHolder>
        <RoomHolder
          selected={selected.includes('annat')}
          onClick={(quanity) => {
            if (selected.includes('annat')) {
              const s = selected.filter((s) => s !== 'annat')
              setSelected(s)
            } else {
              selectRoom({ room: 'annat', quanity: quanity })
            }
          }}>
          <Flex height={'100px'} mt={'20px'} mb={'15px'} width={'100%'} />
          <Text flex={1}>{t('choose_room_text_11')}</Text>
        </RoomHolder>
      </Flex>
      <BackAndNext
        canClickNext={selected.length > 0}
        isLoading={isLoading}
        backClick={backClick}
        nextClick={async () => {
          if (selected.length > 0) {
            setIsLoading(true)
            await addRoomToOrder({ orderId: orderId, rooms: selected, roomOrderError: t('add_room_text') })
            setIsLoading(false)
            next(selected)
          }
        }}
      />
    </>
  )
}
