import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Text } from '../../primitives'
import { colors } from '../../../other/colors/color'

export const TextWithClick = styled(Text)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

TextWithClick.propTypes = {
  ...Text.propTypes,
  hoverColor: PropTypes.string
}

TextWithClick.defaultProps = {
  hoverColor: colors.secondaryLight,
  ...Text.defaultProps
}
