import styled from 'styled-components'
import { Button } from '../../primitives'
import { colors } from "../../../other/colors/color"

const ButtonPrimaryLight = styled(Button)`
  border: ${(props) => props.border};
  &:hover {
    background-color: ${colors.secondary};
  }
  &:focus {
    outline: none;
  }
`

ButtonPrimaryLight.defaultProps = {
  bg: 'primaryLight',
  color: 'black',
  border: '1px solid rgba(255, 255, 255, 0.0)'
}

export { ButtonPrimaryLight }
