import { colors } from './colors/color'

export default {
  fontSizes: [10, 12, 14, 16, 18, 20, 24, 28, 32, 38, 48, 64, 74, 84],
  colors: {
    primary: colors.primary,
    primaryLight: colors.primaryLight,
    primaryDark: colors.primaryDark,
    secondary: colors.secondary,
    secondaryLight: colors.secondaryLight,
    secondaryDark: colors.secondaryDark,
    dark: colors.dark,
    white: colors.white,
    grey: colors.grey
  },
  space: [0, 5, 10, 15, 20, 25, 35, 45, 50, 55, 65, 75, 85],
  breakpoints: ['600px', '960px', '1280px', '1920px'],
  fonts: {
    main: 'futura-pt, futura, sans-seriff'
  }
}
