import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Flex as ReFlex } from 'rebass/styled-components'
import { display } from 'styled-system'

export const Flex = styled(ReFlex)`
  height: ${(props) => props.height};
  min-height: ${(props) => props.minHeight};
  max-height: ${(props) => props.maxHeight};
  justify-content: ${(props) => props.jusifyContent};
  max-width: ${(props) => props.maxWidth};
  alignself: ${(props) => props.alignSelf};
  ${display}
`

export const types = {
  maxWidth: PropTypes.any,
  height: PropTypes.any,
  minHeight: PropTypes.any,
  maxHeight: PropTypes.any,
  width: PropTypes.any,
  flexDirection: PropTypes.any,
  jusifyContent: PropTypes.any,
  alignItems: PropTypes.any,
  alignSelf: PropTypes.any
}

Flex.propTypes = {
  ...types
}
