import { Flex } from '../../primitives'
import React from 'react'
import { LoadingSpinner } from '../lottie/LoadingSpinner'

export const Loading = ({ isLoading, children, ...props }) => {
  if (isLoading) {
    return (
      <Flex height={'40px'} width={'50px'} {...props}>
        <LoadingSpinner />
      </Flex>
    )
  }

  return children
}
