import axios from 'axios'
import { ShowError } from '../helpers/showError'

export const addBudgetToOrder = async ({ orderId, budget, budgetType, budgetError }) => {
  try {
    const url = process.env.REACT_APP_API_URL + '/addBudgetToOrder'
    const response = await axios({
      method: 'post',
      url: url,
      data: {
        orderId,
        budget,
        budgetType
      },
      headers: {
        'content-type': 'application/json'
      }
    })

    return response.data
  } catch (e) {
    console.log('erororo', e.response.data)
    ShowError({
      message: budgetError
    })
  }
}
