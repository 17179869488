import styled from 'styled-components'
import { colors } from "../../../other/colors/color"

export const Input = styled.input`
  padding: 10px 10px 10px 10px;
  border: 1px solid #bebebe;
  width: 100%;
  border-radius: 5px;
  &:focus {
    border: 1px solid ${colors.primary};
  }
`



export const TextArea = styled.textarea`
  padding: 10px 10px 10px 10px;
  border: 1px solid #bebebe;
  width: 100%;
  border-radius: 5px;
  &:focus {
    border: 1px solid ${colors.primary};
  }
`

