import axios from 'axios'
import { ShowError } from '../helpers/showError'

export const addOptionToOrder = async ({ orderId, option, lang, addOptionError }) => {
  console.log(orderId, option, lang)
  try {
    const url = process.env.REACT_APP_API_URL + '/addOptionToOrder'
    const response = await axios({
      method: 'post',
      url: url,
      data: {
        orderId,
        productOption: option,
        lang: lang
      },
      headers: {
        'content-type': 'application/json'
      }
    })

    return response.data
  } catch (e) {
    console.log('erororo', e.response.data)
    ShowError({
      message: addOptionError
    })
  }
}
