import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { se } from "./i18n/se";
import { en } from "./i18n/en";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'se',
    // lng: 'se',
    interpolation: {
      escapeValue: false
    },
    // backend: {
    //   loadPath: `${getCurrentHost}/i18n/{{lng}}.json`,
    // },
    resources:{
      en: { translation: en },
      se: { translation: se },
    },
    detection: {
      // order: ['path', "localStorage", 'navigator','queryString'],
      order: ['path'],
      lookupFromPathIndex: 0,
      checkWhitelist: true,
    }
  });

export default i18n;